import {SubsystemCode} from '../types';

/**
 * Перелинковка кода подсистемы с цветом для неё.
 *
 * Главное, нужно помнить, что цвет передаётся не целиком, а только
 * значениям RGB для возможности устанавливать прозрачность
 */
export const SUBSYSTEM_COLORS_MAP: {[subsystemCode in SubsystemCode]: string} = {
  Layout: 'var(--ntc-ss-layout)',
  Plumbing: 'var(--ntc-ss-plumbing)',
  Plumbing2: 'var(--ntc-ss-plumbing)',
  Structure: 'var(--ntc-ss-structure)',
  Structure2: 'var(--ntc-ss-structure)',
  HVAC: 'var(--ntc-ss-hvac)',
  HVAC2: 'var(--ntc-ss-hvac)',
  OMAR: 'var(--ntc-ss-omar)',
  OMAR2: 'var(--ntc-ss-omar)',
  OMAR3: 'var(--ntc-ss-omar)',
  Electrical: 'var(--ntc-ss-electrical)',
  Electrical2: 'var(--ntc-ss-electrical)',
  SpacePlanning: 'var(--ntc-ss-space-planning)',
  Final: 'var(--ntc-ss-final)',
  MEP: 'var(--ntc-ss-mep)',
};
