import {SubsystemCode} from '../types';

export const SUBSYSTEM_ICONS_MAP: {[subsystemCode in SubsystemCode]: string} = {
  Layout: '@tui.table',
  Structure: '@tui.box',
  Structure2: '@tui.box',
  HVAC: '@tui.wind',
  HVAC2: '@tui.wind',
  Plumbing: '@tui.droplet',
  Plumbing2: '@tui.droplet',
  Electrical: '@tui.zap',
  Electrical2: '@tui.zap',
  SpacePlanning: '@tui.layers',
  OMAR: '@tui.codesandbox',
  OMAR2: '@tui.codesandbox',
  OMAR3: '@tui.codesandbox',
  Final: '@tui.command',
  MEP: '@tui.cpu',
};
